<template>
  <div>
    <component-dialog
      ref="pharmaceuticalServicesDialog"
      dialog-type="information"
      dialog-title="Pharmazeutische Dienstleistungen (pDL)"
      dialog-width="medium"
    >
      <template #content>
        <p>
          In der aktuellen Medikation sind Arzneimittel enthalten, die die Durchführung weiterer pharmazeutischer
          Dienstleistungen (nach VOASG) nahelegen.
        </p>

        <ul class="mt-4 space-y-4">
          <li v-if="internalServices.blood_pressure.has" class="space-y-2">
            <h3 class="text-lg font-medium">Standardisierte Risikoerfassung hoher Blutdruck</h3>
            <p>
              Patientinnen und Patienten erhalten mit dieser Dienstleistung ein Angebot, den Erfolg ihrer medikamentösen
              Blutdruckeinstellung standardisiert in der Apotheke kontrollieren zu lassen.
            </p>
            <p>Folgende Arzneimittel implizieren diese pDL:</p>
            <ul class="list-disc ml-6">
              <li
                v-for="(keyFam, index) in internalServices.blood_pressure.keyFams"
                :key="index"
                v-text="famName(keyFam)"
              />
            </ul>
            <div class="flex items-center space-x-2">
              <component-icon>launch</component-icon>
              <a
                href="https://www.abda.de/pharmazeutische-dienstleistungen/standardisierte-risikoerfassung-hoher-blutdruck/"
                target="_blank"
              >
                Informationsseite der ABDA
              </a>
            </div>
          </li>

          <li v-if="internalServices.inhaler.has" class="space-y-2">
            <h3 class="text-lg font-medium">
              Erweiterte Einweisung in die korrekte Arzneimittelanwendung mit Üben der Inhalationstechnik
            </h3>
            <p>
              Durch diese Dienstleistung erhalten Patientinnen und Patienten ein Angebot, ihre Inhalationstechnik nach
              einem standardisierten Prozess qualitätsgesichert zu üben.
            </p>
            <p>Folgende Arzneimittel implizieren diese pDL:</p>
            <ul class="list-disc ml-6">
              <li v-for="(keyFam, index) in internalServices.inhaler.keyFams" :key="index" v-text="famName(keyFam)" />
            </ul>
            <div class="flex items-center space-x-2">
              <component-icon>launch</component-icon>
              <a href="https://www.abda.de/pharmazeutische-dienstleistungen/inhalationstechnik/" target="_blank">
                Informationsseite der ABDA
              </a>
            </div>
          </li>
        </ul>
      </template>
    </component-dialog>

    <component-button
      v-if="internalServices?.blood_pressure?.has || internalServices?.inhaler?.has"
      class="p4umc-primary p4umc-flat"
      label="weitere pDL"
      :icon-button="true"
      @click="openDialog()"
    >
      <component-icon class="text-xl">medical_services</component-icon>
    </component-button>
  </div>
</template>

<script>
  import {computed, reactive, ref, watch} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "MedicationComponentPharmaceuticalServices",

    components: {ComponentButton, ComponentIcon, ComponentDialog},

    props: {
      services: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();
      const pharmaceuticalServicesDialog = ref(null);
      const internalServices = ref(props.services);

      const openDialog = () => {
        pharmaceuticalServicesDialog.value.open();
      };

      watch(
        () => props.services,
        (newValue) => {
          console.log(newValue);
          internalServices.value = newValue;
        },
      );

      const famName = (keyFam) => {
        return page.props.record.medications.find((medication) => medication.abdata_key_fam === keyFam).fam || null;
      };

      return {
        pharmaceuticalServicesDialog,
        internalServices,
        openDialog,
        famName,
      };
    },
  };
</script>
