<template>
  <div>
    <div
      class="accordion-header bg-[linear-gradient(0deg,#fff,#f0f0f0)] flex w-full py-3 text-base text-gray-800 text-left bg-white border-gray-200 cursor-pointer"
      :class="{'border-b': !showAdditionalData}"
      @click.prevent="showAdditionalData = !showAdditionalData"
    >
      <div class="flex items-center font-medium">
        <component-icon v-if="showAdditionalData" class="text-gray-900 text-xl">arrow_drop_up</component-icon>
        <component-icon v-else class="text-gray-900 text-xl">arrow_drop_down</component-icon>

        <div class="flex space-x-1 items-center">
          <span>Weitere Informationen</span>
          <component-info-icon placement="right">
            <template #content>
              <p>z. B. Einnahmehinweise, Verordner, Quelle, Teilbarkeit etc.</p>
            </template>
          </component-info-icon>
        </div>
      </div>
    </div>

    <div v-if="showAdditionalData" class="grid grid-cols-12 gap-x-2 gap-y-4">
      <!-- dosageInfo -->
      <div class="col-start-1 col-span-9">
        <component-textarea
          v-model="dosage.info"
          :disabled="!isEditable"
          label="Hinweise zur Medikation (wird in Medikationsplan übernommen)"
          maxlength="80"
          rows="2"
          :validation="medicationForm?.errors['dosage.info'] ?? null"
          helper-text="Darf maximal einen Zeilenumbruch beinhalten."
          @input="handleDosageInfo"
        />
      </div>

      <!-- additional.source -->
      <div class="col-start-1 col-span-9">
        <label class="text-gray-500 text-sm">Vorhanden in</label>
        <div class="mt-2 grid gap-x-2 gap-y-4 grid-cols-3">
          <component-checkbox v-model="bmpCheckbox" :disabled="!isEditable" color="accent">
            Medikationsplan / -datei
          </component-checkbox>

          <component-checkbox v-model="brownBagCheckbox" :disabled="!isEditable" color="accent">
            Brown Bag
          </component-checkbox>

          <component-checkbox v-model="otherCheckbox" :disabled="!isEditable" color="accent">
            Andere Datenquelle
          </component-checkbox>
        </div>
      </div>

      <!-- additional.decreed -->
      <div class="col-start-1 col-span-3 whitespace-nowrap mt-1">
        <component-select
          :model-value="additionalInformationForm.decreed"
          label="Verordnet durch / Herkunft"
          :nullable="true"
          :disabled="!isEditable"
          :options="additionalinformationDecreed"
          @change="handleDecreed"
        />
      </div>

      <!-- additional.since -->
      <component-full-date-input
        ref="since"
        v-model="additionalInformationForm.since"
        class="col-span-3 mt-1"
        label="Anw. seit"
        :disabled="!isEditable"
        @input="handleDateInput('dateSince', $event)"
      />

      <!-- additional.to -->
      <component-full-date-input
        v-model="additionalInformationForm.to"
        :validation="invalidPeriodError"
        class="col-span-3 mt-1"
        label="Anw. bis"
        :disabled="!isEditable"
        @input="handleDateInput('dateTo', $event)"
      />

      <!-- additional.requirements -->
      <component-select
        :id="'requirements'"
        ref="requirements"
        :model-value="additionalInformationForm.requirements"
        :name="'requirements'"
        :nullable="true"
        :options="requirementOptions"
        class="col-start-1 col-span-3"
        label="Anforderungen Arzneiform"
        :disabled="!isEditable"
        @change="handleRequirements"
      />

      <p class="col-start-1 col-span-12">
        Das Merkmal „Sonde“ kann unter den Patientenangaben als

        <a
          target="_blank"
          :href="$route('patients.edit', {id: $page.props.patient.id}) + '#patient-attributes-data'"
          class="mt-1 group/link hover:no-underline inline-flex items-center space-x-0.5"
        >
          <span class="group-hover/link:underline">individuelle Besonderheit</span>
          <component-icon
            class="text-sm leading-tight text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
            :clickable="true"
            title="Öffnet in neuem Tab"
          >
            launch
          </component-icon>
        </a>

        hinterlegt werden.
      </p>
    </div>
  </div>
</template>

<script>
  import {onBeforeMount, ref, watch} from "vue";
  import {cloneDeep, each} from "lodash";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  import {checkAdditionalInformationDetailDialog} from "@pages/Records/Components/Sections/MedicationBar/Utils/medicationConsistencyCheck.js";
  import {additionalinformationDecreed} from "@pages/Records/Components/Result/Enums.js";

  export default {
    name: "MedicationComponentDetailDialogAdditionalData",

    components: {
      ComponentInfoIcon,
      ComponentFullDateInput,
      ComponentSelect,
      ComponentCheckbox,
      ComponentTextarea,
      ComponentIcon,
    },

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      medicationForm: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    emits: ["valid", "invalid", "changeForm"],

    setup(props, {emit}) {
      const showAdditionalData = ref(false);
      const bmpCheckbox = ref(false);
      const brownBagCheckbox = ref(false);
      const otherCheckbox = ref(false);
      const invalidPeriodError = ref(null);

      const additionalInformationForm = ref({
        decreed: props.medicationForm.value.additionalinformation?.decreed ?? null,
        requirements: props.medicationForm.value.additionalinformation?.requirements ?? null,
        since: props.medicationForm.value.additionalinformation?.since ?? null,
        source: props.medicationForm.value.additionalinformation?.source,
        sideeffects: props.medicationForm.value.additionalinformation?.sideeffects,
        to: props.medicationForm.value.additionalinformation?.to ?? null,
      });

      const dosage = ref({});

      const requirementOptions = {
        splitable: "Teilbar",
        mortarable: "Mörserbar",
        suspendable: "Suspendierbar",
      };

      watch(
        () => additionalInformationForm.value.source,
        (newSourceList) => {
          each(newSourceList, (source) => {
            if (source === "bmp") {
              bmpCheckbox.value = true;
            } else if (source === "brownbag") {
              brownBagCheckbox.value = true;
            } else if (source === "other") {
              otherCheckbox.value = true;
            }
          });
        },
      );

      watch(
        () => bmpCheckbox.value,
        (newValue) => {
          if (newValue) {
            addToList("bmp");
          } else {
            removeFromList("bmp");
          }
        },
      );

      watch(
        () => brownBagCheckbox.value,
        (newValue) => {
          if (newValue) {
            addToList("brownbag");
          } else {
            removeFromList("brownbag");
          }
        },
      );

      watch(
        () => otherCheckbox.value,
        (newValue) => {
          if (newValue) {
            addToList("other");
          } else {
            removeFromList("other");
          }
        },
      );

      onBeforeMount(() => {
        dosage.value = cloneDeep(props.medicationForm.value.dosage);

        bmpCheckbox.value = additionalInformationForm.value.source.includes("bmp");
        brownBagCheckbox.value = additionalInformationForm.value.source.includes("brownbag");
        otherCheckbox.value = additionalInformationForm.value.source.includes("other");

        showAdditionalData.value = checkAdditionalInformationDetailDialog(props.medicationForm.value);
      });

      const addToList = (sourceName) => {
        if (!additionalInformationForm.value.source.includes(sourceName)) {
          additionalInformationForm.value.source.push(sourceName);
          emit("changeForm", {key: "additionalinformation.source", newValue: additionalInformationForm.value.source});
        }
      };

      const removeFromList = (sourceName) => {
        const indexFromList = additionalInformationForm.value.source.indexOf(sourceName);

        if (indexFromList > -1) {
          additionalInformationForm.value.source.splice(indexFromList, 1);
          emit("changeForm", {key: "additionalinformation.source", newValue: additionalInformationForm.value.source});
        }
      };

      const handleDosageInfo = () => {
        emit("changeForm", {key: "dosage.info", newValue: dosage.value.info});
      };

      const handleDecreed = (newValue) => {
        additionalInformationForm.value.decreed = newValue;
        emit("changeForm", {key: "additionalinformation.decreed", newValue: additionalInformationForm.value.decreed});
      };

      const handleDateInput = (type, newDateValue) => {
        if (type === "dateSince") {
          additionalInformationForm.value.since = newDateValue;
        } else {
          additionalInformationForm.value.to = newDateValue;
        }

        const validPeriod = isSinceDateBeforeToDate(
          additionalInformationForm.value.to,
          additionalInformationForm.value.since,
        );

        if (validPeriod) {
          emit("valid", "invalidPeriod");
          invalidPeriodError.value = null;

          emit("changeForm", {key: "additionalinformation.since", newValue: additionalInformationForm.value.since});
          emit("changeForm", {key: "additionalinformation.to", newValue: additionalInformationForm.value.to});
        } else {
          invalidPeriodError.value = "Ungültiger Zeitraum.";
          emit("invalid", "invalidPeriod");
        }
      };

      function isSinceDateBeforeToDate(toDate, sinceDate) {
        const toDateTimestamp = new Date(toDate).getTime();
        const sinceDateTimestamp = new Date(sinceDate).getTime();

        if (toDate === null || sinceDate === null) {
          return true;
        }

        return toDateTimestamp > sinceDateTimestamp;
      }

      const handleRequirements = (newValue) => {
        additionalInformationForm.value.requirements = newValue;

        emit("changeForm", {
          key: "additionalinformation.requirements",
          newValue: additionalInformationForm.value.requirements,
        });
      };

      return {
        additionalinformationDecreed,
        showAdditionalData,
        bmpCheckbox,
        brownBagCheckbox,
        otherCheckbox,
        invalidPeriodError,
        additionalInformationForm,
        dosage,
        requirementOptions,
        handleDosageInfo,
        handleDecreed,
        handleDateInput,
        handleRequirements,
      };
    },
  };
</script>
