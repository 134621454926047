<template>
  <section
    v-if="sharedInformation && (sharedInformation.shared_by_me || sharedInformation.shared_with_me)"
    class="print:hidden"
  >
    <div class="relative -bottom-4 flex flex-row-reverse cursor-default">
      <span v-if="sharedInformation && sharedInformation.shared_by_me" class="flex items-center space-x-2">
        <component-icon>call_made</component-icon>
        <a href="#" @click.prevent="openShareStatusDialog()">von mir freigegeben</a>
      </span>

      <span v-else-if="sharedInformation && sharedInformation.shared_with_me" class="flex items-center space-x-2">
        <component-icon>call_received</component-icon>
        <a href="#" @click.prevent="openShareStatusDialog()">für mich freigegeben</a>
      </span>
    </div>
  </section>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";
  import {inject, onMounted, ref} from "vue";

  export default {
    name: "PagesRecordGeneralShareStatus",

    components: {ComponentIcon},

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    emits: ["open:shareStatusDialog"],

    setup(props, {emit}) {
      const sharedInformation = ref(null);
      const axios = inject("$axios");

      onMounted(() => {
        axios
          .get(route("api.records.share-information", {patient: props.patient.id, record: props.record.id}))
          .then((response) => {
            sharedInformation.value = response.data;
          });
      });
      function openShareStatusDialog() {
        emit("open:shareStatusDialog");
      }

      return {
        openShareStatusDialog,
        sharedInformation,
      };
    },
  };
</script>
