<template>
  <div
    class="border-l-4"
    :class="{
      'border-very-high-risk': claim.class === 'red',
      'border-high-risk': claim.class === 'orange',
      'border-medium-risk': claim.class === 'yellow',
      'border-low-risk': claim.class === 'grey' || claim.class === 'gray',
      'border-white': claim.class === null,
    }"
  >
    <a
      :id="claim.section"
      :data-risk-goto-target="claim.class + '-' + claim.check.mc_check_id"
      class="p-2 bg-gradient-to-t from-white to-gray-100 !no-underline hover:!no-underline !text-gray-700 group flex items-center space-x-2"
      href="#"
      role="button"
      :aria-expanded="open"
      :aria-controls="claim.section + '-content'"
      :data-test="'listClaimItem-' + claim.section + '-summaryLink-toggleClaim'"
      @click.prevent="toggleClaim()"
    >
      <component-icon v-if="open" class="text-xl print:hidden">arrow_drop_up</component-icon>
      <component-icon v-else class="text-xl print:hidden">arrow_drop_down</component-icon>

      <div
        class="flex-1 line-clamp-2 group-hover:!text-mcred-500"
        :class="{
          'font-semibold': claim.communicationdoc || claim.communicationpatient,
          'line-through': claim.hidden,
        }"
      >
        <span>{{ claim.summary ?? "Check-Treffer " + claimNumber }}</span>

        <template
          v-if="[1, 31, 2, 24, 17, 30, 32, 33, 34].indexOf(mcCheckId) >= 0 && claim.causedby && claim.causedby.extra"
        >
          - ABDATA Farbschema:
          <template v-if="typeof claim.causedby.extra.rating !== 'undefined'">
            <!-- interaction, contraindication or questionable contraindication -->
            <span v-if="claim.causedby.extra.rating === 1" class="text-abdata-red">rot</span>
            <span v-if="claim.causedby.extra.rating === 2" class="text-abdata-orange">orange</span>
            <span v-if="claim.causedby.extra.rating === 3" class="text-abdata-yellow">gelb</span>
          </template>
          <template v-else-if="typeof claim.causedby.extra.relevance !== 'undefined'">
            <span v-if="claim.causedby.extra.relevance === 60" class="text-abdata-darkred">dunkelrot</span>
            <span v-if="claim.causedby.extra.relevance === 50" class="text-abdata-red">rot</span>
            <span v-if="claim.causedby.extra.relevance === 40" class="text-abdata-orange">orange</span>
            <span v-if="claim.causedby.extra.relevance === 30" class="text-abdata-yellow">gelb</span>
            <span v-if="claim.causedby.extra.relevance === 20" class="text-abdata-brown">braun</span>
            <span v-if="claim.causedby.extra.relevance === 10" class="text-abdata-blue">blau</span>
            <span v-if="claim.causedby.extra.relevance === 0" class="text-abdata-gray">grau</span>
          </template>
        </template>
      </div>

      <aside class="flex space-x-4 text-sm print:hidden">
        <div v-if="!open && !claim.hidden && claim.comment_count" class="flex items-center space-x-1">
          <component-icon class="text-xl">mode_comment</component-icon>
          <span>{{ claim.comment_count }}</span>
          <span v-if="claim.comment_count === 1">Kommentar</span>
          <span v-else>Kommentare</span>
        </div>

        <div v-if="!open && !claim.hidden && claim.confidential_comment_count" class="flex items-center space-x-1">
          <component-icon custom="doctor" class="size-3" />
          <span>{{ claim.confidential_comment_count }}</span>
          <span v-if="claim.confidential_comment_count === 1">Arztrückmeldung</span>
          <span v-else>Arztrückmeldungen</span>
        </div>

        <div v-if="!claim.hidden && claim.communicationdoc" class="flex items-center space-x-1">
          <component-icon class="text-xl">check</component-icon>
          Arzt
        </div>

        <div v-if="!claim.hidden && claim.communicationpatient" class="flex items-center space-x-1">
          <component-icon class="text-xl">check</component-icon>
          Patient
        </div>

        <div v-if="claim.hidden">nicht relevant</div>
      </aside>
    </a>

    <div
      :id="claim.section + '-content'"
      :aria-labelledby="claim.section"
      class="relative p-2 items-start space-x-8 print:break-before-avoid-page"
      :class="{'hidden print:!flex': !open, flex: open}"
    >
      <main class="flex-1">
        <nav class="h-10 lg:h-auto print:hidden">
          <ul class="flex flex-wrap border-b border-gray-200 space-x-2">
            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeTab === 'tab-doc',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2 flex items-center"
                :data-test="'listClaimItem-' + claim.section + '-tabButton-doc'"
                @click="changeTab('tab-doc')"
              >
                <component-tooltip class="normal-case" tooltip="Kommunikation im PDF">
                  <component-checkbox
                    v-model="form.communicationdoc"
                    :color="activeTab === 'tab-doc' ? 'primary' : 'default'"
                    :data-test="'listClaimItem-' + claim.section + '-checkbox-communicationdoc'"
                  />
                </component-tooltip>
                <span>an Arzt</span>
              </button>
            </li>
            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeTab === 'tab-patient',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2 flex items-center"
                :data-test="'listClaimItem-' + claim.section + '-tabButton-patient'"
                @click="changeTab('tab-patient')"
              >
                <component-tooltip class="normal-case" tooltip="Kommunikation im PDF">
                  <component-checkbox
                    v-model="form.communicationpatient"
                    :color="activeTab === 'tab-patient' ? 'primary' : 'default'"
                    :data-test="'listClaimItem-' + claim.section + '-checkbox-communicationpatient'"
                  />
                </component-tooltip>
                <span>an Patient</span>
              </button>
            </li>
            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeTab === 'tab-original',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2"
                :data-test="'listClaimItem-' + claim.section + '-tabButton-original'"
                @click="changeTab('tab-original')"
              >
                Originaltext
              </button>
            </li>
          </ul>
        </nav>

        <div class="print:space-y-2">
          <div
            :class="{hidden: activeTab !== 'tab-doc'}"
            class="print:hidden [.print-doc_&]:print:block [.print-archive_&]:print:block"
            role="tabpanel"
          >
            <div class="p-1 font-semibold hidden [.print-archive_&]:print:block print:break-after-avoid-page">
              AN ARZT:
            </div>
            <pages-record-result-list-claim-text-box
              v-model:problem="form.problemdoc"
              v-model:solution="form.solutiondoc"
              :visible="open && activeTab === 'tab-doc'"
              :comment="claim.comment"
              :mc-check-id="mcCheckId"
              :claim-number="hasSiblingClaims ? claimNumber : null"
              :data-test="'listClaimItem-' + claim.section + '-tab-doc'"
              @open:core-info-dialog="
                (data) => {
                  refCoreInfoDialog.open(data);
                }
              "
            />
          </div>
          <div
            :class="{hidden: activeTab !== 'tab-patient'}"
            class="print:hidden [.print-patient_&]:print:block [.print-archive_&]:print:block"
            role="tabpanel"
          >
            <div class="p-1 font-semibold hidden [.print-archive_&]:print:block print:break-after-avoid-page">
              AN PATIENT:
            </div>
            <pages-record-result-list-claim-text-box
              v-model:problem="form.problempatient"
              v-model:solution="form.solutionpatient"
              :visible="open && activeTab === 'tab-patient'"
              :comment="claim.comment"
              :mc-check-id="mcCheckId"
              :claim-number="hasSiblingClaims ? claimNumber : null"
              :data-test="'listClaimItem-' + claim.section + '-tab-patient'"
              @open:core-info-dialog="
                (data) => {
                  refCoreInfoDialog.open(data);
                }
              "
            />
          </div>
          <div :class="{hidden: activeTab !== 'tab-original'}" class="print:hidden" role="tabpanel">
            <pages-record-result-list-claim-text-box
              :visible="open && activeTab === 'tab-original'"
              :problem="claim.problem"
              :solution="claim.solution"
              :read-only="true"
              :comment="claim.comment"
              :mc-check-id="mcCheckId"
              :claim-number="hasSiblingClaims ? claimNumber : null"
              :data-test="'listClaimItem-' + claim.section + '-tab-original'"
              @open:core-info-dialog="
                (data) => {
                  refCoreInfoDialog.open(data);
                }
              "
            />
          </div>
        </div>
      </main>

      <aside
        class="w-1/4 min-w-[16rem] absolute right-0 shadow-lg bg-white pl-2 lg:pl-0 lg:shadow-none lg:relative print:relative print:shadow-none print:min-w-[auto]"
      >
        <nav class="print:hidden">
          <ul class="w-full flex flex-wrap justify-between border-b border-gray-200 space-x-2">
            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeInteractionTab === 'tab-pdf',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2"
                :data-test="'listClaimItem-' + claim.section + '-interactionTabButton-pdf'"
                @click="changeInteractionTab('tab-pdf')"
              >
                Bericht
              </button>
            </li>

            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeInteractionTab === 'tab-documentation',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2"
                :data-test="'listClaimItem-' + claim.section + '-interactionTabButton-documentation'"
                @click="changeInteractionTab('tab-documentation')"
              >
                Doku
              </button>
            </li>

            <li class="flex-1" />

            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeInteractionTab === 'tab-chat',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2"
                :data-test="'listClaimItem-' + claim.section + '-interactionTabButton-chat'"
                @click="changeInteractionTab('tab-chat')"
              >
                <component-badge v-if="claim?.comment_count && claim.comment_count > 0">
                  <template #icon>{{ claim.comment_count }}</template>
                  Team
                </component-badge>
                <span v-else>Team</span>
              </button>
            </li>
          </ul>
        </nav>

        <div :class="{block: visibleTabPanels, 'hidden print:block': !visibleTabPanels}" class="mt-3">
          <div :class="{'hidden print:block': activeInteractionTab !== 'tab-pdf'}" role="tabpanel">
            <pages-record-result-list-claim-interaction-pdf
              v-model:communicationdoc="form.communicationdoc"
              v-model:communicationpatient="form.communicationpatient"
              v-model:hidden="form.hidden"
              v-model:usernote="form.usernote"
              v-model:usernotepatient="form.usernotepatient"
              :claim="claim"
              :data-test="'listClaimItem-' + claim.section + '-interactionTab-pdf'"
              @open:confidential-comments-dialog="openConfidentialCommentsDialog()"
            />
          </div>

          <div :class="{hidden: activeInteractionTab !== 'tab-documentation'}" class="print:hidden" role="tabpanel">
            <pages-record-result-list-claim-interaction-documentation
              :mc-check-id="mcCheckId"
              :claim="claim"
              :data-test="'listClaimItem-' + claim.section + '-interactionTab-documentation'"
            />
          </div>

          <div :class="{hidden: activeInteractionTab !== 'tab-chat'}" class="print:hidden" role="tabpanel">
            <pages-record-result-list-claim-interaction-chat
              :comments="claimComments"
              :comment-section="mcCheckId + '-' + claim.section"
              :patient-id="patientId"
              :record-id="recordId"
              :data-test="'listClaimItem-' + claim.section + '-interactionTab-claimInteractionChat'"
              @open:comments-dialog="openCommentsDialog()"
              @open:share-dialog="$emit('open:shareDialog')"
            />
          </div>
        </div>
      </aside>
    </div>
    <component-core-info-dialog ref="refCoreInfoDialog" />

    <component-comments-dialog
      ref="refCommentsDialog"
      :record-id="recordId"
      :patient-id="patientId"
      :confidential="false"
      :comments="claimComments"
      :data-test="'listClaimItem-' + claim.section + '-comments-dialog'"
    />

    <component-comments-dialog
      ref="refConfidentialCommentsDialog"
      :record-id="recordId"
      :patient-id="patientId"
      :confidential="true"
      :comments="claimConfidentialComments"
      :data-test="'listClaimItem-' + claim.section + '-confidentialComments-dialog'"
    />

    <component-dialog
      ref="refConfirmCommunicationDoc"
      dialog-title="Hinweis zur Kommunikation"
      :data-test="'listClaimItem-' + claim.section + '-dialog-confirmCommunicationdoc'"
      @primary-button-clicked="confirmCommunicationdoc()"
      @cancel-button-clicked="form.communicationdoc = false"
    >
      <template #content>
        <p>
          Sie haben bereits drei Checktreffer ausgewählt. Kollegen (v. a. Ärzte) bevorzugen kurze, kompakte
          Informationen. Wir empfehlen das Übermitteln weiterer Treffer auf AMTS-relevante Notwendigkeit zu prüfen.
        </p>
        <p class="mt-4">Nutzen Sie „Zusammenfassung der Analyse“ (s. u.).</p>
      </template>
    </component-dialog>

    <component-dialog
      ref="refConfirmCommunicationPatient"
      dialog-title="Ergebnisse an Patient übermitteln?"
      :data-test="'listClaimItem-' + claim.section + '-dialog-confirmCommunicationpatient'"
      @primary-button-clicked="confirmCommunicationpatient()"
      @cancel-button-clicked="form.communicationpatient = false"
    >
      <template #content>
        <p>
          <strong>CAVE:</strong>
          die hier genannten Informationen richten sich in ihrer Formulierung an Fachkreise (primär an die Apotheke) und
          sind für den Dialog zwischen Apotheker und Arzt gedacht (AMTS-Hinweise vom Apotheker an den Arzt).
        </p>
        <p class="mt-4">
          Eine Übersetzung in eine patientengerechte Fassung ist nicht im Tool vorgesehen und müsste ggf. von Ihnen
          selbst verbalisiert werden (alle Texte können von Ihnen editiert / geändert werden).
        </p>
      </template>
    </component-dialog>
  </div>
</template>

<script>
  import {computed, inject, onMounted, onUnmounted, ref, watch} from "vue";
  import {debounce} from "lodash";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import PagesRecordResultListClaimTextBox from "@pages/Records/Components/Result/ResultList/ClaimTextBox.vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import ComponentCoreInfoDialog from "@components/Dialogs/CoreInfoDialog/CoreInfoDialog.vue";
  import PagesRecordResultListClaimInteractionDocumentation from "@pages/Records/Components/Result/ResultList/ClaimInteractionDocumentation.vue";
  import PagesRecordResultListClaimInteractionChat from "@pages/Records/Components/Result/ResultList/ClaimInteractionChat.vue";
  import ComponentBadge from "@components/Badges/Badge.vue";
  import PagesRecordResultListClaimInteractionPdf from "@pages/Records/Components/Result/ResultList/ClaimInteractionPdf.vue";
  import ComponentCommentsDialog from "@components/Dialogs/CommentsDialog.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";

  export default {
    name: "PagesRecordResultListClaimItem",
    components: {
      ComponentDialog,
      ComponentTooltip,
      ComponentCheckbox,
      ComponentCommentsDialog,
      PagesRecordResultListClaimInteractionPdf,
      ComponentBadge,
      PagesRecordResultListClaimInteractionChat,
      PagesRecordResultListClaimInteractionDocumentation,
      ComponentCoreInfoDialog,
      PagesRecordResultListClaimTextBox,
      ComponentIcon,
    },
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      mcCheckId: {
        type: Number,
        required: true,
      },
      claim: {
        type: Object,
        required: true,
      },
      claimNumber: {
        type: Number,
        required: true,
      },
      hasSiblingClaims: {
        type: Boolean,
        required: true,
      },
    },
    emits: ["open:shareDialog"],
    setup(props) {
      const refCoreInfoDialog = ref(null);
      const refConfirmCommunicationDoc = ref(null);
      const refConfirmCommunicationPatient = ref(null);
      const refCommentsDialog = ref(null);
      const refConfidentialCommentsDialog = ref(null);
      const open = ref(false);
      const activeTab = ref("tab-doc");

      const activeInteractionTab = ref("tab-pdf");
      const isFloating = ref(false);
      const visibleTabPanels = ref(true);

      const comments = inject("comments");
      const claimComments = computed(() => {
        return comments.value.filter(
          (comment) =>
            comment.section === props.mcCheckId + "-" + props.claim.section && comment.confidential === false,
        );
      });
      const claimConfidentialComments = computed(() => {
        return comments.value.filter(
          (comment) => comment.section === props.mcCheckId + "-" + props.claim.section && comment.confidential === true,
        );
      });

      const form = useForm({
        // problem and solution texts:
        problemdoc: props.claim.problemdoc,
        solutiondoc: props.claim.solutiondoc,
        problempatient: props.claim.problempatient,
        solutionpatient: props.claim.solutionpatient,

        // interactions:
        communicationdoc: props.claim.communicationdoc,
        communicationpatient: props.claim.communicationpatient,
        hidden: props.claim.hidden,
        usernote: props.claim.usernote,
        usernotepatient: props.claim.usernotepatient,
      });

      const save = debounce(function () {
        form.patch(
          route("claims.update", {
            patient: props.patientId,
            record: props.recordId,
            check: props.claim.check_id,
            claim: props.claim.id,
          }),
          {
            preserveScroll: true,
            preserveState: true,
          },
        );
      }, 800);

      watch(
        () => [
          form.problemdoc, // 0
          form.solutiondoc, // 1
          form.problempatient, // 2
          form.solutionpatient, // 3
          form.communicationdoc, // 4
          form.communicationpatient, // 5
          form.hidden, // 6
          form.usernote, // 7
          form.usernotepatient, // 8
        ],
        (newValues, oldValues) => {
          // checkbox form.communicationdoc has been activated
          if (oldValues[4] === false && newValues[4] === true) {
            // check, if already 3 claims are communicated to the doc
            if (communicationDocCount.value === 3) {
              refConfirmCommunicationDoc.value.open();
            } else {
              // immediately confirm
              confirmCommunicationdoc();
            }
          }
          // checkbox form.communicationpatient has been activated
          else if (oldValues[5] === false && newValues[5] === true) {
            // check if this is the first claim communicated to the patient
            if (
              communicationPatientCount.value === 0 &&
              // skip the confirmation for patient communication on
              // all checks within "compliance" and "prevention"
              props.mcCheckId !== 18 && // noncompliance
              props.mcCheckId !== 27 && // explanationrequired
              props.mcCheckId !== 20 && // prevention
              props.mcCheckId !== 31 // interaction_food
            ) {
              refConfirmCommunicationPatient.value.open();
            } else {
              // immediately confirm
              confirmCommunicationpatient();
            }
          }
          // all other changes will be saved immediately
          else {
            save();
          }
        },
      );

      const triggerCloseClaim = inject("triggerCloseClaim");
      const closeClaimSiblings = inject("closeClaimSiblings");
      function toggleClaim() {
        if (open.value === false && typeof closeClaimSiblings === "function") {
          closeClaimSiblings(props.claim.id);
        }
        open.value = !open.value;
      }
      watch(triggerCloseClaim, (openClaimId) => {
        if (openClaimId !== props.claim.id) {
          open.value = false;
        }
      });

      function changeTab(newTab) {
        activeTab.value = newTab;
      }

      const handleResizeBreakpoint = (event) => {
        isFloating.value =
          event.detail.breakpoint === null ||
          event.detail.breakpoint === "xs" ||
          event.detail.breakpoint === "sm" ||
          event.detail.breakpoint === "md";
        if (!isFloating.value) {
          visibleTabPanels.value = true;
        }
      };

      onMounted(() => {
        window.addEventListener("breakpoint", handleResizeBreakpoint);
      });

      onUnmounted(() => {
        window.removeEventListener("breakpoint", handleResizeBreakpoint);
      });

      function changeInteractionTab(newTab) {
        if (isFloating.value && activeInteractionTab.value === newTab) {
          visibleTabPanels.value = !visibleTabPanels.value;
        } else {
          visibleTabPanels.value = true;
          activeInteractionTab.value = newTab;
        }
      }

      function openCommentsDialog() {
        refCommentsDialog.value.open();
      }

      function openConfidentialCommentsDialog() {
        refConfidentialCommentsDialog.value.open();
      }

      const communicationDocCount = computed(() => {
        return usePage().props.record.checks.reduce((total, check) => {
          return (
            total + check.claims.filter((claim) => claim.type === "redlight" && claim.communicationdoc === true).length
          );
        }, 0);
      });

      const communicationPatientCount = computed(() => {
        return usePage().props.record.checks.reduce((total, check) => {
          // skip all checks within "compliance" and "prevention" when
          // summing up the communicationPatientCount value
          if (
            check.mc_check_id === 18 || // noncompliance
            check.mc_check_id === 27 || // explanationrequired
            check.mc_check_id === 20 || // prevention
            check.mc_check_id === 31 // interaction_food
          ) {
            return total;
          }

          return (
            total +
            check.claims.filter((claim) => claim.type === "redlight" && claim.communicationpatient === true).length
          );
        }, 0);
      });

      function confirmCommunicationdoc() {
        // always disable "hidden"
        form.hidden = false;
        // set as active tab
        activeTab.value = "tab-doc";
        save();
      }

      function confirmCommunicationpatient() {
        // always disable "hidden"
        form.hidden = false;
        // set as active tab
        activeTab.value = "tab-patient";
        save();
      }

      return {
        refCoreInfoDialog,
        refConfirmCommunicationDoc,
        refConfirmCommunicationPatient,
        confirmCommunicationdoc,
        confirmCommunicationpatient,
        form,
        save,
        toggleClaim,
        open,
        activeTab,
        changeTab,
        isFloating,
        activeInteractionTab,
        changeInteractionTab,
        visibleTabPanels,
        claimComments,
        claimConfidentialComments,
        refCommentsDialog,
        refConfidentialCommentsDialog,
        openCommentsDialog,
        openConfidentialCommentsDialog,
        communicationDocCount,
        communicationPatientCount,
      };
    },
  };
</script>
